<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="page-title-box">
          <div class="page-title-right d-flex">
            <div class="me-4 d-flex">
              <label class="col-form-label me-2">Budget Year</label>
              <div style="width: 150px">
                <v-select label="name" v-model="year" :options="years" :clearable="false" :reduce="(name) => name.id"
                  @option:selected="getPlansByYear($event)">
                </v-select>
              </div>
            </div>
            <label class="col-form-label me-2">Month</label>
            <div style="width: 200px">
              <v-select label="name" v-model="month" :options="months" :clearable="false" :reduce="(name) => name.id"
                @option:selected="getPlansByMonth">
              </v-select>
            </div>
            <label class="col-form-label me-2 ms-4">Process</label>
            <div style="width: 150px">
              <v-select v-model="trainning_enrollment" label="name" :options="trainning_process"
                @option:selected="getStatus($event)" :clearable="false">
              </v-select>
            </div>
            <div @click="refreshData" class="icon-css">
              <i style="color: #4a81d4" class="fe-rotate-cw"></i>
            </div>
          </div>
          <h4 class="page-title">Training Plan</h4>
        </div>
      </div>
    </div>
    <!-- end page title -->
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <place-holder v-if="loading"></place-holder>
            <div class="table-responsive" v-if="!loading">
              <table class="table dt-responsive nowrap w-100 mb-3 table-hover" id="trainingMonthly_plan_datatable"
                v-if="!loading" style="margin-top: 10px !important">
                <!-- style="display: none" -->
                <thead v-if="!loading">
                  <tr>
                    <th>Employee Type</th>
                    <th>Module Group</th>
                    <th>Module</th>
                    <th>Trainer</th>
                    <th>Status</th>
                    <th>Plan Status</th>
                  </tr>
                </thead>
                <tbody v-if="!loading">
                  <template v-for="(item, index) in filterTrainingPlan" :key="index">
                    <!-- class="table-inlineData" -->
                    <tr>
                      <!-- table-leftBorder -->
                      <td class="table-rowData">
                        {{ item.employee_type_name }}
                      </td>
                      <td class="table-rowData">
                        {{ item.module_group_name }}
                      </td>
                      <td class="table-rowData">{{ item.module_name }}</td>
                      <td class="table-rowData">
                        <span v-for="(trainer, index) in item.trainers" :key="index">{{ trainer.name }}
                          <span v-if="index + 1 < item.trainers.length">, </span>
                        </span>
                      </td>
                      <td class="table-rowData">
                        <span class="badge bg-danger" v-if="item.is_plan_confirmed == 0" data-bs-toggle="tooltip"
                          title="Plan is rejected by Trainer!">
                          Reject
                        </span>
                        <span class="badge bg-warning" v-else-if="item.is_plan_confirmed == 4" data-bs-toggle="tooltip"
                          title="Plan is cancelled by Trainer!">
                          Cancel
                        </span>
                        <span class="badge bg-info" v-else-if="item.expired_date >= current_date" data-bs-toggle="tooltip"
                          title="Plan is during registration period!" style="cursor: pointer">Pending
                        </span>
                        <span class="badge bg-success" v-else-if="item.from_date > current_date &&
                          item.is_plan_confirmed != 0 &&
                          item.is_plan_confirmed != 4
                          " data-bs-toggle="tooltip" title="Plan is active during confirmation period!"
                          style="cursor: pointer">Active
                        </span>
                        <span class="badge bg-secondary" v-else data-bs-toggle="tooltip" title="Plan is Over!"
                          style="cursor: pointer">Inactive
                        </span>
                      </td>
                      <!-- table-rightBorder -->
                      <td class="table-rowData">
                        <router-link :to="{
                          name: 'training-confirm-edit',
                          params: { id: item.id },
                        }">
                          <button v-if="item.is_plan_confirmed == 4" type="button"
                            class="btn btn-warning btn-sm badge-cursor">
                            Cancelled
                          </button>
                          <button style="background-color: #4a81d4; border-color: #4a81d4;"
                            v-if="item.is_plan_confirmed == 3" type="button" class="btn btn-primary btn-sm badge-cursor">
                            Created
                          </button>
                          <button v-if="item.is_plan_confirmed == 2" type="button"
                            class="btn btn-info btn-sm badge-cursor">
                            Onprogress
                          </button>
                          <button v-if="item.is_plan_confirmed == 1" type="button"
                            class="btn btn-success btn-sm badge-cursor">
                            Confirmed
                          </button>
                          <button v-if="item.is_plan_confirmed == 0" type="button"
                            class="btn btn-danger btn-sm badge-cursor">
                            Rejected
                          </button>
                        </router-link>
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.table.dataTable.nowrap td {
  white-space: inherit !important;
}

.custom-width {
  max-width: 70px;
}
</style>

<script>
//Datatable TrainingMonthly Plan
import "datatables.net/js/jquery.dataTables.min.js";

import "datatables.net-bs5/css/dataTables.bootstrap5.min.css";
import "datatables.net-bs5/js/dataTables.bootstrap5.min.js";
import "datatables.net-responsive-bs5/css/responsive.bootstrap5.min.css";
import "datatables.net-responsive-bs5/js/responsive.bootstrap5.min.js";

import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { useToast } from "vue-toastification";

import $ from "jquery";
import axios from "axios";

import moment from "moment";

export default {
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      current_date: "",
      loading: false,
      trainning_process: [
        "Cancelled",
        "Created",
        "Onprogress",
        "Confirmed",
        "Rejected",
      ],
      trainning_enrollment: "",
      monthlyTrainingPlan: [],
      employees: this.$store.getters["odoo/getAllEmployees"],
      filterPlanLists: [],
      filterTrainingPlan: [],
      month: "",
      months: [],
      year: "",
      years: [],
      yearId: "",
      process_data: "",
      // filterData: false,
      current_user_id: this.$store.state.auth.user.employee_id,
      baseUrl: process.env.VUE_APP_BASE_URL,
      baseUrlHRIS: process.env.VUE_APP_COURSIA_BASE_URL,
      // defineTrainingHead: process.env.VUE_APP_DEFINE_TRAINING_HEAD,
    };
  },
  methods: {
    async getBudgetYear() {
      this.loading = true;
      await axios
        .get(`${this.baseUrl}admin/v1/yearly-training-plans`)
        .then((response) => {
          this.years = response.data.data;
          this.loading = false;
        });
    },
    // async getAllMonths() {
    //   axios.get(`${this.baseUrl}admin/v1/get-months`).then((response) => {
    //     let obj = response.data.data;
    //     this.months = Object.keys(obj).map((key) => {
    //       return { id: key, name: obj[key] };
    //     });
    //   });
    // },

    async getPlansByYear(value) {
      this.yearId = value.id;
      this.trainning_enrollment = "";
      this.monthlyTrainingPlan = "";
      this.filterPlanLists = [];
      this.filterTrainingPlan = [];
      this.month = "";
      // this.filterData = false;
      await this.getAllMonths(this.yearId);
      this.loading = true;
      this.$Progress.start();
      await axios
        .get(
          `${this.baseUrl}admin/v1/trainer-views/monthly-plans?budget_year_id=${this.yearId}`
        )
        .then((response) => {
          this.monthlyTrainingPlan = response.data.data;

          // this.monthlyTrainingPlan.map((item) => {
          //   item.trainers.map((data) => {
          //     if (data.employee_id == this.current_user_id) {
          //       this.filterPlanLists.push(item);
          //     }
          //   });
          // });

          // this.filterTrainingPlan = this.filterPlanLists.map((item) => {
          //   var trainerList = item.trainers.map((data) => {
          //     let employee = this.checklist(data.employee_id);
          //     let obj = {
          //       id: employee.emp_id,
          //       name: employee.name,
          //     };
          //     return obj;
          //   });

          //   let plan = {
          //     id: item.id,
          //     employee_type_name: item.employee_type_name,
          //     module_group_name: item.module_group_name,
          //     module_name: item.module_name,
          //     trainers: trainerList,
          //     expired_date: item.expired_date,
          //     from_date: item.from_date,
          //     is_plan_confirmed: item.is_plan_confirmed,
          //   };
          //   return plan;
          // });

          if (
            // this.$store.state.auth.user.employee_id == this.defineTrainingHead
            this.$store.state.auth.user.roles[0] == "Training Head" ||
            this.$store.state.auth.user.roles[0] == "Admin" ||
            this.$store.state.auth.user.roles[0] == "Super Admin"
          ) {
            this.filterTrainingPlan = this.monthlyTrainingPlan.map((item) => {
              var trainerList = item.trainers.map((data) => {
                let employee = this.checklist(data.employee_id);
                let obj = {
                  id: employee.emp_id,
                  name: employee.name,
                };
                return obj;
              });

              let plan = {
                id: item.id,
                employee_type_name: item.employee_type_name,
                module_group_name: item.module_group_name,
                module_name: item.module_name,
                trainers: trainerList,
                expired_date: item.expired_date,
                from_date: item.from_date,
                is_plan_confirmed: item.is_plan_confirmed,
              };
              return plan;
            });
          } else {
            this.monthlyTrainingPlan.map((item) => {
              item.trainers.map((data) => {
                if (data.employee_id == this.current_user_id) {
                  this.filterPlanLists.push(item);
                }
              });
            });

            this.filterTrainingPlan = this.filterPlanLists.map((item) => {
              var trainerList = item.trainers.map((data) => {
                let employee = this.checklist(data.employee_id);
                let obj = {
                  id: employee.emp_id,
                  name: employee.name,
                };
                return obj;
              });

              let plan = {
                id: item.id,
                employee_type_name: item.employee_type_name,
                module_group_name: item.module_group_name,
                module_name: item.module_name,
                trainers: trainerList,
                expired_date: item.expired_date,
                from_date: item.from_date,
                is_plan_confirmed: item.is_plan_confirmed,
              };
              return plan;
            });
          }

          this.loading = false;
          this.$Progress.finish();
        });
      $("#trainingMonthly_plan_datatable").DataTable({
        responsive: false,
        destroy: true,
      });
    },

    async getAllMonths(year_id) {
      axios
        .get(`${this.baseUrl}admin/v1/get-months/${year_id}`)
        .then((response) => {
          let obj = response.data.data;
          this.months = Object.keys(obj).map((key) => {
            return { id: key, name: obj[key] };
          });
        });
    },

    async getPlansByMonth() {
      if (this.yearId) {
        this.monthlyTrainingPlan = "";
        this.filterPlanLists = [];
        this.filterTrainingPlan = [];
        this.trainning_enrollment = "";
        this.loading = true;
        this.$Progress.start();
        await axios
          .get(
            `${this.baseUrl}admin/v1/trainer-views/monthly-plans?budget_year_id=${this.yearId}&month=${this.month}`
          )
          .then((response) => {
            this.monthlyTrainingPlan = response.data.data;

            if (
              // this.$store.state.auth.user.employee_id == this.defineTrainingHead
              this.$store.state.auth.user.roles[0] == "Training Head" ||
              this.$store.state.auth.user.roles[0] == "Admin" ||
            this.$store.state.auth.user.roles[0] == "Super Admin"
            ) {
              this.filterTrainingPlan = this.monthlyTrainingPlan.map((item) => {
                var trainerList = item.trainers.map((data) => {
                  let employee = this.checklist(data.employee_id);
                  let obj = {
                    id: employee.emp_id,
                    name: employee.name,
                  };
                  return obj;
                });

                let plan = {
                  id: item.id,
                  employee_type_name: item.employee_type_name,
                  module_group_name: item.module_group_name,
                  module_name: item.module_name,
                  trainers: trainerList,
                  expired_date: item.expired_date,
                  from_date: item.from_date,
                  is_plan_confirmed: item.is_plan_confirmed,
                };
                return plan;
              });
            } else {
              this.monthlyTrainingPlan.map((item) => {
                item.trainers.map((data) => {
                  if (data.employee_id == this.current_user_id) {
                    this.filterPlanLists.push(item);
                  }
                });
              });

              this.filterTrainingPlan = this.filterPlanLists.map((item) => {
                var trainerList = item.trainers.map((data) => {
                  let employee = this.checklist(data.employee_id);
                  let obj = {
                    id: employee.emp_id,
                    name: employee.name,
                  };
                  return obj;
                });

                let plan = {
                  id: item.id,
                  employee_type_name: item.employee_type_name,
                  module_group_name: item.module_group_name,
                  module_name: item.module_name,
                  trainers: trainerList,
                  expired_date: item.expired_date,
                  from_date: item.from_date,
                  is_plan_confirmed: item.is_plan_confirmed,
                };
                return plan;
              });
            }

            this.loading = false;
            this.$Progress.finish();
          });
        $("#trainingMonthly_plan_datatable").DataTable({
          responsive: false,
          destroy: true,
        });
      } else {
        this.month = "";
        this.toast.error("Please Choose Budget Year First!");
      }
    },

    async getEmployees() {
      // this.loading = true;
      // this.$Progress.start();
      // await axios
      //   .get(`${this.baseUrlHRIS}api/employees`, {
      //     headers: {
      //       "content-type": "text/plain",
      //     },
      //   })
      //   .then((response) => {
      //     this.employees = response.data.data;

      //     this.loading = false;
      //     this.$Progress.finish();
      //   })
      //   .catch(() => {
      //     this.toast.error("Not Found Employees!");
      //   });
      if (this.employees.length == 0) {
        this.loading = true;
      } else {
        this.loading = false;
      }
    },

    checklist(empId) {
      let resultArray;
      this.employees.forEach(function (emp) {
        if (emp.emp_id == empId) {
          resultArray = emp;
        }
      });
      return resultArray;
    },

    async getAllMonthlyTrainingPlan() {
      await this.getEmployees();
      this.loading = true;
      this.$Progress.start();
      await axios
        .get(`${this.baseUrl}admin/v1/trainer-views/monthly-plans`)
        .then((response) => {
          this.monthlyTrainingPlan = response.data.data;

          if (
            // this.$store.state.auth.user.employee_id == this.defineTrainingHead
            this.$store.state.auth.user.roles[0] == "Training Head" ||
            this.$store.state.auth.user.roles[0] == "Admin" ||
            this.$store.state.auth.user.roles[0] == "Super Admin"
          ) {
            this.filterTrainingPlan = this.monthlyTrainingPlan.map((item) => {
              var trainerList = item.trainers.map((data) => {
                let employee = this.checklist(data.employee_id);
                let obj = {
                  id: employee.emp_id,
                  name: employee.name,
                };
                return obj;
              });

              let plan = {
                id: item.id,
                employee_type_name: item.employee_type_name,
                module_group_name: item.module_group_name,
                module_name: item.module_name,
                trainers: trainerList,
                expired_date: item.expired_date,
                from_date: item.from_date,
                is_plan_confirmed: item.is_plan_confirmed,
              };
              return plan;
            });
          } else {
            this.monthlyTrainingPlan.map((item) => {
              item.trainers.map((data) => {
                if (data.employee_id == this.current_user_id) {
                  this.filterPlanLists.push(item);
                }
              });
            });

            this.filterTrainingPlan = this.filterPlanLists.map((item) => {
              var trainerList = item.trainers.map((data) => {
                let employee = this.checklist(data.employee_id);
                let obj = {
                  id: employee.emp_id,
                  name: employee.name,
                };
                return obj;
              });

              let plan = {
                id: item.id,
                employee_type_name: item.employee_type_name,
                module_group_name: item.module_group_name,
                module_name: item.module_name,
                trainers: trainerList,
                expired_date: item.expired_date,
                from_date: item.from_date,
                is_plan_confirmed: item.is_plan_confirmed,
              };
              return plan;
            });
          }

          this.loading = false;
          this.$Progress.finish();
        });
      $("#trainingMonthly_plan_datatable").DataTable({
        // paging: false,
        // searching: false,
        responsive: false,
        destroy: true,
      });
    },
    async getStatus(value) {
      if (this.yearId) {
        this.monthlyTrainingPlan = "";
        this.filterPlanLists = [];
        this.filterTrainingPlan = [];
        this.loading = true;
        this.$Progress.start();
        if (value == "Rejected") {
          this.process_data = 0;
        } else if (value == "Confirmed") {
          this.process_data = 1;
        } else if (value == "Onprogress") {
          this.process_data = 2;
        } else if (value == "Created") {
          this.process_data = 3;
        } else if (value == "Cancelled") {
          this.process_data = 4;
        }
        // this.filterData = true;

        await axios
          .get(
            `${this.baseUrl}admin/v1/trainer-views/monthly-plans?budget_year_id=${this.yearId}&month=${this.month}&process_data=${this.process_data}`
          )
          .then((response) => {
            this.monthlyTrainingPlan = response.data.data;

            if (
              // this.$store.state.auth.user.employee_id == this.defineTrainingHead
              this.$store.state.auth.user.roles[0] == "Training Head" ||
              this.$store.state.auth.user.roles[0] == "Admin" ||
            this.$store.state.auth.user.roles[0] == "Super Admin"
            ) {
              this.filterTrainingPlan = this.monthlyTrainingPlan.map((item) => {
                var trainerList = item.trainers.map((data) => {
                  let employee = this.checklist(data.employee_id);
                  let obj = {
                    id: employee.emp_id,
                    name: employee.name,
                  };
                  return obj;
                });

                let plan = {
                  id: item.id,
                  employee_type_name: item.employee_type_name,
                  module_group_name: item.module_group_name,
                  module_name: item.module_name,
                  trainers: trainerList,
                  expired_date: item.expired_date,
                  from_date: item.from_date,
                  is_plan_confirmed: item.is_plan_confirmed,
                };
                return plan;
              });
            } else {
              this.monthlyTrainingPlan.map((item) => {
                item.trainers.map((data) => {
                  if (data.employee_id == this.current_user_id) {
                    this.filterPlanLists.push(item);
                  }
                });
              });

              this.filterTrainingPlan = this.filterPlanLists.map((item) => {
                var trainerList = item.trainers.map((data) => {
                  let employee = this.checklist(data.employee_id);
                  let obj = {
                    id: employee.emp_id,
                    name: employee.name,
                  };
                  return obj;
                });

                let plan = {
                  id: item.id,
                  employee_type_name: item.employee_type_name,
                  module_group_name: item.module_group_name,
                  module_name: item.module_name,
                  trainers: trainerList,
                  expired_date: item.expired_date,
                  from_date: item.from_date,
                  is_plan_confirmed: item.is_plan_confirmed,
                };
                return plan;
              });
            }

            this.loading = false;
            this.$Progress.finish();
          });
        $("#trainingMonthly_plan_datatable").DataTable({
          destroy: true,
          responsive: false,
        });
      } else {
        this.trainning_enrollment = "";
        this.toast.error("Please Choose Budget Year First!");
      }
    },
    refreshData() {
      this.year = "";
      this.month = "";
      this.yearId = "";
      this.monthlyTrainingPlan = "";
      this.filterPlanLists = [];
      this.filterTrainingPlan = [];
      this.trainning_enrollment = "";
      // this.filterData = false;
      this.getBudgetYear();
      // this.getAllMonths();
      this.getAllMonthlyTrainingPlan();
      this.current_date = moment().format("YYYY-MM-DD");
    },
    clearFilter() {
      $.fn.dataTable.ext.search.pop();
      $("#trainingMonthly_plan_datatable").DataTable().draw();
    },
  },
  created() {
    this.clearFilter();
    this.getBudgetYear();
    // this.getAllMonths();
    this.getAllMonthlyTrainingPlan();
    this.current_date = moment().format("YYYY-MM-DD");
  },
  components: {
    vSelect,
  },
};
</script>

<style>
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #4a81d4;
  border-color: #4a81d4;
}
</style>
